
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "cooperative-umbrella-policy-listing",
  components: {},
  data() {
    return {
      policyIsEmpty: false,
      policies: [
        {
          number: "",
          reference: "",
          businessType: { publicId: "", name: "" },
          businessClass: { publicId: "", name: "" },
          package: "",
          totalPolicies: 0,
          publicId: "",
        },
      ],
    };
  },
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      displayDatatable,
    } = ResusableFunctions();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Policies", ["Cooperative"]);
      displayDatatable(["basicExample"]);
    });

    return {
      useReusableNavigation,
      reinitialization,
    };
  },
  created() {
    //Set page title
    document.title = "Umbrella Policies | " + process.env.VUE_APP_TITLE;
    this.getCooperativePolicies();
  },
  methods: {
    getCooperativePolicies() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.COOPERATIVE_UMBRELLA_POLICY_ROUTE)
          .then(({ data }) => {
            //Assign data to variable to bebpassed as a prop
            this.policies = data.data;

            if (data.data == "") {
              this.policyIsEmpty = true;
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
  },
  formatDate(dateString) {
    return variables.formatDate(dateString);
  },
});
